(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["parse-xml"] = factory();
	else
		root["parseXml"] = factory();
})(typeof self === 'undefined' ? this : self, function() {
return 